import React from 'react';
import ReactGA from 'react-ga';
import { Document, Page, pdfjs } from "react-pdf";
import resumePDF from './images/TonySciantarelliResume.pdf'

import './App.scss'; 

import foos from './images/foos.jpg';
import fun from './images/fun.jpg';
import family from './images/family.jpg';
import ven from './images/ven.png';
import pets from './images/pets.jpg';
import Sketching from './images/sketching.jpg';
import Sketching2 from './images/sketching2.jpg';
import SprintArtifacts from './images/SprintArtifacts.jpg';
import linkedIn from './images/linkedin.svg';
import twitter from './images/twitter.svg';
import github from './images/github.svg';
import burgerBtn from './images/TS-UI-BurgerButton.svg';

import UX1 from './images/TS-UX-1.jpg';
import UX2 from './images/TS-UX-2.jpg';
import UX3 from './images/TS-UX-3.jpg';
import UX4 from './images/TS-UX-4.jpg';
import UX5 from './images/TS-UX-5.jpg';
import UX7 from './images/TS-UX-7.jpg';

import DS1 from './images/TS-DS1.jpg';
import DS2 from './images/TS-DS2.jpg';
import DS3 from './images/TS-DS3.jpg';
import DS4 from './images/TS-DS4.jpg';
import DS5 from './images/TS-DS5.jpg';
import DS6 from './images/TS-DS6.jpg';

import IA1 from './images/TS-IA1.jpg';
import IA2 from './images/TS-IA2.jpg';
import IA3 from './images/TS-IA3.jpg';
import IA4 from './images/TS-IA4.jpg';
import IA5 from './images/TS-IA5.jpg';
import IA6 from './images/TS-IA6.jpg';
import IA7 from './images/TS-IA7.jpg';
import IA8 from './images/TS-IA8.jpg';
import IA9 from './images/TS-IA9.jpg';
import IA10 from './images/TS-IA10.jpg';
import IA12 from './images/TS-IA12.jpg';
import IA20 from './images/TS-IA20.jpg';

import SL1 from './images/TS-SL1.jpg';
import SL3 from './images/TS-SL3.jpg';
import SL4 from './images/TS-SL4.jpg';
import SL5 from './images/TS-SL5.jpg';
import SL6 from './images/TS-SL6.jpg';
import SL7 from './images/TS-SL7.jpg';
import SL8 from './images/TS-SL8.jpg';
import SL10 from './images/TS-SL10.jpg';
import SL13 from './images/TS-SL13.jpg';
import SL14 from './images/TS-SL14.jpg';
import SL15 from './images/TS-SL15.jpg';

import CT1 from './images/TS-CT1.jpg';
import CT2 from './images/TS-CT2.jpg';
import CT3 from './images/TS-CT3.jpg';
import CT4 from './images/TS-CT4.jpg';
import CT5 from './images/TS-CT5.jpg';
import CT6 from './images/TS-CT6.jpg';
import CT7 from './images/TS-CT7.jpg';
import CT8 from './images/TS-CT8.jpg';

class PageController extends React.Component{
  constructor(props)
  {
    super(props)
    this.state = {activePage: this.props.curPage }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
}

  render(){
    if(this.props.curPage === "HO")
    {return(<HO />)}
    
    if(this.props.curPage === "UX")
    {return(<UX />)}

    if(this.props.curPage === "DS")
    {return(<DS />)}
    
    if(this.props.curPage === "IA")
    {return(<IA />)}

    if(this.props.curPage === "SL")
    {return(<SL />)}

    if(this.props.curPage === "CT")
    {return(<CT />)}
     if(this.props.curPage === "RE")
    {return(<RE />)}
  }
  
}

//----------------------------------------
function HO(){
  return(
    <div className="content">
      
        <h1>About Me</h1> 
        
       
        
        <ul className="projectList">
        <li className="projectSeparator"></li>
        <li className="projectTitle">At Work</li>
        <li className="projectDescription">
            <p>14+ years of experience managing projects across industries and disciplines establishing design standards that reflect brand identity and best practices for information architecture and design. </p>
          </li>
        <li className="projectImage"><img src={UX1} alt="Tony giving a presentation to the Marriott International Global Digital Team."/></li>
        <li className="projectDescription">
            <p> I work cross-functionally with leadership teams, including design, product management, content strategy, engineering, and marketing during the product development lifecycle to ensure high-quality content. </p>
            
          </li>
         
          <li className="projectImage"><img src={ven} alt="Ven Diagram showing Tony at the intersect of design, product, and development"/></li>
          <li className="projectDescription">
           <p> Proven leadership skills with the ability to attract, hire, and motivate teams, lead strategic initiatives, and nurture a design culture. Ability to handle multiple projects simultaneously in a fast-paced, dynamic environment while delivering scalable products and features on time and within budget. </p>
          </li>
          <li className="projectSeparator"></li>
          <li className="projectTitle">At Home</li>
          <li className="projectDescription">
           <p>While I absolutely love my career it's important to stop designing experiences and instead experience life too.  I wouldn't be where I am today without a fun-loving, inspiring, and slightly crazy family that I'm blessed to have.   </p>
          </li>
          <li className="projectImage"><img src={family} alt="Family Photo of Tony, his parents, fiancé, and daughter all wearing Washington Nationals apparel."/></li> 
          <li className="projectDescription">
           <p>My wanderlust and life-long affinity for camping come from my lovely mother while my borderline absurd relationship with sports comes from my father.</p>
          </li>
         
          <li className="projectImage"><img src={fun} alt="Collage of Tony camping, visiting Amsterdam with is fiancee, zip lining, and attending a baseball game."/></li>
          
          <li className="projectDescription">
           <p>In 2012 I was lucky enough to finally meet my partner, and together we're raising a beautiful daughter, and a pack of cats &amp; dogs. </p>
          </li>
        
          <li className="projectImage"><img src={pets} alt="Collage of the Sciantarelli pets.  Two dogs named Chewy and Rosie, and three cats named Moose, Mimi, and Emma."/></li>
          <li className="projectDescription">
           <p>Chewy, Rosie, Moose, Mimi, and Emma are all very happy to be on my homepage </p>

          </li>
          <li className="projectTitle">Open Challenge</li>
          <li className="projectImage"><img src={foos} alt="foosball table"/></li>
          <li className="projectDescription">
           <p>Since college I've been fanatic over foosball.  I don't currently have my own table, but would be happy to come challenge you on yours!</p>

          </li>

         
         
         
        
          </ul>
      </div>
  )
}
//----------------------------------------
function UX(){
  return(
    <div className="content">
        <h1>UX Design</h1>
        
        <ul className="projectList">
        <li className="projectSeparator"></li>
          <li className="projectTitle">Marriott International Program Pages</li>
          <li className="projectDescription">
            <p>UX Lead and acting Product Owner for the Loyalty pages for Marriott.com after the aquisition of Starwood Preferred Guest.  </p>
          </li>
          <li className="projectImage"><img src={UX3} alt="Mobile landing pages for Marriott’s Loyalty Program Pages.  Tony was the UX lead for the creation of these responsive pages."/></li>

          <li className="projectSeparator"></li>
          <li className="projectTitle">
            4 Global Programs: Aquisitions, Mergers, and Members all on a new architecture.</li>
          <li className="projectDescription">
          <p>Designed and built for scale these pages set a new standard in performance and capalities on a brand new architecture.</p>
            <p>18 Pages in 17 languages</p> 

            <ul>
              <li>Provides main door for new member aquisition.</li>
              <li>Sets visual standard for Program digital branding</li>
             
            </ul>
          </li>

           
        
         
         
         
         
         
         
         
         
          <li className="projectImage"><img src={UX2} alt="Mobile landing pages themed to support Starwood Preferred Guest brand identity."/></li>
          <li className="projectSeparator"></li>
          <li className="projectTitle">+300 Additional Pages</li>
          
          <li className="projectDescription">
          
<p>Created new standard for reusability and flexibility for publishing on Marriott's new ARIES architecture.</p>
            <p> The same templates, themes, and tokens have been used to announce the creation of Marriott's new Bonvoy loyalty program in addition to other key areas of the marriott.com.</p>
            <ul>
              <li>Group Travel Pages</li>
              <li>Corporate Info and Culture Pages</li>
              <li>Brand Promotional Landing Pages and Mini-sites</li>
            </ul>
          </li>
          
          <li className="projectImage"><img src={UX4} alt="Desktop landing page of Marriott Rewards Home Page"/></li>
          <li className="projectSeparator"></li>
          <li className="projectTitle">User Research Testing &amp; Design</li>
          <li className="projectDescription">
            <p>All of the above images represent the output of a single well planned UX strategy aligned with the business and technology. All successfull strategy is born first from research and testing.  </p>
            <p>Throughout my career I've lead and been heavily involved in designing and testing prototypes for:</p>
            <ul>
              <li>Qualitative Usability Testing in lab</li>
              <li>Qualitative Feasibility testing in market</li>
              <li>Remote and in-person guided research planning and testing</li>
              <li>Quantitative A/B Testing in product</li>
              <li>Quantitative MultiVariate production</li>
            </ul>
          </li>
          <li className="projectImage"><img src={IA12} alt="Tony conducting user research with hotel guests."/></li>
          <li className="projectSeparator"></li>
          <li className="projectTitle">Personas / Empathy Maps</li>
          <li className="projectDescription">
            <p>Understanding how your users will use your product and feature require real-life customer interaction.  I lead UX workshops to uncover usable, viable, and technically feasible solutions by:</p>
            <ul>
              <li>Understanding user needs and expectaions.</li>
              <li>Evaluating current tools / methods / competitors that the users currently use.</li>
              <li>Reducing friction, redundancy, and distraction from the user experience.</li>
            </ul>
          </li>
          <li className="projectImage"><img src={IA4} alt="Sample empathy map created by Tony during the creation of the Reservations Users Group Product."/></li>
          <li className="projectDescription">
            <p>Personas mapped against real-life context, roadblocks, and goals allow you test your solutions against use-cases rather than intuition.</p>
          </li>
          <li className="projectImage"><img src={IA5} alt="Another sample empathy map created by Tony during the creation of the Reservations Users Group Product."/></li>

          <li className="projectSeparator"></li>
          <li className="projectTitle">User/Feature Journey Mapping</li>
          <li className="projectDescription">
            <p>Knowing when and how users are going to interact with your design allows you test iterate and selectively choose optimized touchpoints along the user journey where your feature will prove most valuable.</p>
          </li>
          <li className="projectImage"><img src={IA1} alt="Sample of simple user flow."/></li>
          <li className="projectDescription">
            <p>As Digital UX Lead for Loyalty &amp; Personalization I was able to create delightful experiences through relevant information presented conveniently throughout the user experience.</p>
          </li>
          <li className="projectImage"><img src={IA2} alt="Slightly more complex user flow with personalization techniques applied."/></li>
          <li className="projectDescription">
            <p>Context is everything, and today's digital customer expects seamless experiences curated to their preferences.  I have experience in designing for:</p>
            <ul>
              <li>Member Level</li>
              <li>Geography / Language</li>
              <li>Travel Preferences</li>
            </ul>
          </li>
          <li className="projectImage"><img src={IA3} alt="Complex user flow accounting for member level and entry points variations."/></li>
          <li className="projectSeparator"></li>
          <li className="projectTitle">Validation &amp; Stress Testing</li>
          <li className="projectDescription">
            <p>Before investing large amounts of money and resources to an untested feature, I have designed, prototyped and tested features with clear measurable goals at all levels of fidelity.</p>
          </li>
          <li className="projectImage"><img src={IA6} alt="Lean UX Canvas and IA for messaging currency conversion feature."/></li>
          <li className="projectDescription">
            <p>Identifying opportunities based on user data, competitve analysis, and qualitative research.  I have worked closely with the following methods of analyzing data and feedback.</p>
            <ul>
              <li>Voice of Customer Verbatim</li>
              <li>In-Person customer feedback</li>
              <li>Research Top-Line Reports</li>
              <li>Product Road Maps</li>
              <li>Site Performance and customer pathway analysis</li>
            </ul>
          </li>
          <li className="projectImage"><img src={UX5} alt="Sample UI Flow of feature existence and prominence within user journey of searching and booking a hotel room."/></li>
          <li className="projectDescription">
            <p>Iterating and measuring the success of different placements, patterns, and messaging from discovery through delivery.</p>
          </li>
          <li className="projectImage"><img src={IA7} alt="Evaluation of touchpoint relevance for currency converter feature."/></li>
          <li className="projectSeparator"></li>
          <li className="projectTitle">Right Fidelity Prototyping</li>
          <li className="projectDescription">
            <p>Among my strongest abilities is my skill of designing and developing prototypes to rapidly test ideas, features, products, and services.  Whether it's paper prototyping or more complex live data a/b testing the value lies in the data and feedback received. </p>
          </li>
          <li className="projectImage"><img src={IA10} alt="Low fidelity sketches for improving the story that hotel websites tell."/></li>
          <li className="projectSeparator"></li>
          <li className="projectTitle">Development Ready User Stories</li>
          <li className="projectDescription">
            <p> Living prototypes coupled with technical writing have helped me drive more than 50 features / pages from design through development and acceptance testing in the last 2 years alone.  Writing user stories and acceptance criteria in Jira and documenting standards in Confluence.</p>
          </li>
          <li className="projectImage"><img src={UX7} alt="Example of a mobile application feature evaluation for development estimation and sprint backlog."/></li>

        </ul>



      </div>
  )
}
//----------------------------------------
function DS(){
  return(
    <div className="content">
        <h1>Design Systems &amp; Curation</h1>
        <ul className="projectList">
        <li className="projectSeparator"></li>

          <li className="projectTitle">Card Pattern Library</li>
          
          <li className="projectDescription">
            <p>In 2017 / 2108 I lead a team that created 10 components that would become the foundation and precedent for hundreds of pages.  Configurable, Adaptive, Reusable, and Dynamic components or CARDS can now be found in over 70,000 different places in the Marriott Digital ecosystem.</p>
          </li>
          <li className="projectImage"><img src={DS2} alt="Low fidelity wireframes to test design tokens and theming."/></li>
          <li className="projectDescription">
            <p>Following the Atomic Design model of Brad Frost, I worked to inventory the most commonly used atoms, elements, and organisms used in Marriott's digital channels.  </p>
            <ul>
                <li>Low-Fi Wireframing</li>
                <li>Document contextual use-cases</li>
                <li>Identify branding / theme opportunities</li>
                <li>Prescribe responsive and adaptive behaviors</li>
            </ul>
          </li>
          <li className="projectSeparator"></li>
          
          <li className="projectTitle">Abstraction &amp; Stress Testing</li>
          
          <li className="projectImage"><img src={DS3} alt="High and low fidelity prototype and wireframes to document adaptive and responsive behavior."/></li>
          <li className="projectDescription">
            <p> After identifying 10 most used patterns I built a Hi-Fi Prototype that simulated language translations via google translate api, differing layout widths (100%, 50/50, 33/33/33, 25/25/25/25)</p>
            <ul>
                <li>Document responsive behavior</li>
                <li>Establish typographic and color values for branded themes</li>
                <li>Write requirements for reusability and configurability</li>
                <li>Sprint Planning and Product Backlog Grooming  </li>
              </ul>
          </li>
          <li className="projectSeparator"></li>
          <li className="projectTitle">Hi-Fidelity Prototyping</li>
          <li className="projectImage"><img src={DS4} alt="Living prototype to test layout flexibility and language constraints of text article component."/></li>
          <li className="projectSeparator"></li>
          <li className="projectTitle">Source of Truth for Development</li>
          <li className="projectDescription">
            <p>Living prototypes are a silver bullet for establishing and maintaining a dynamic and usable design system.  There are many tools that try to magically simplify this step, but in my experience it's better to use these tools after a design system has been adopted.</p>
          </li>
          <li className="projectImage"><img src={DS5} alt="Living prototype to test layout and language constraints of an image / text article component."/></li>
          <li className="projectSeparator"></li>
          <li className="projectTitle">Branded Style Guides</li>
          <li className="projectImage"><img src={DS1} alt="Thumbnail Preview of branded ui style guide for 1 of over 30 brands."/></li>
          
          <li className="projectDescription">
            <p>For over 7 years I've been intimately involved in Marriott's Digital Design system.  With over 30 brands and a global reach this presents many unique challenges that have taught me the value of a strong design system, and the traps that are easy to fall into while trying to establish one.  </p>
              <ul>
                <li>Creation / Curation / Distribution of Style-Guides</li>
                <li>Abstraction of Patterns</li>
                <li>Accessibility and Globalization</li>
                <li>Design and Development Hand-Off</li>
              </ul>
          </li>
          <li className="projectSeparator"></li>

          <li className="projectTitle">Design Tokens</li>
          <li className="projectDescription">
            <p> One of the techniques often discussed, but seemingly misunderstood are design tokens.  Great design systems are born when designers and developers meet in the middle with honest discussions of variables, context, and style. </p>
          </li>
          <li className="projectImage"><img src={DS6} alt="Sample structure of brand design token to dictate theme options across channels and experiences."/></li>
        </ul>



      </div>
  )
}
//-----------------------------------------
function IA(){
  return(
    <div className="content">
        <h1>IA &amp; User Research</h1>
        <ul className="projectList">
       
          <li className="projectDescription">
            <ul>
              <li>Chewy</li>
              <li>Rosie</li>
              <li>Moose</li>
              <li>MiMi</li>
              <li>Emma</li>
            </ul>
            
          </li>
          
          
        </ul>



      </div>
  )
}
//----------------------------------------
function SL(){
  return(
    <div className="content">
        <h1>Strategy &amp; Leadership</h1>
        <ul className="projectList">
        <li className="projectSeparator"></li>
          <li className="projectTitle">From Sketches to Standard Operating Procedure</li>
          <li className="projectImage"><img src={Sketching} alt="Examples of paper prototypes / white board strategy "/></li>
          <li className="projectDescription">
            <p>Throughout my tenure at Marriott I've been able to serve as thought leader amongst my peers, and grown into a strategic decision maker in the formation of products, pages, and the design system.</p>
            <p>I will never claim to be an illustrator, but feel most at home huddled around a whiteboard with a team trying to solve tricky problems. </p>
          </li>
          <li className="projectImage"><img src={Sketching2} alt="White board storyboarding sketches"/></li>
          <li className="projectDescription">
            <p>I believe that my ability to empathize and understand differing perspectives, interpretations, and goals provides me with an unique knack for building shared understanding beyond a surface shared vocabulary.</p>
           
          </li>
          <li className="projectImage"><img src={SprintArtifacts} alt="Agile stories after grooming / development / continued iteration."/></li>
          <li className="projectDescription">
            <p>While strong in conceptual visioning I'm also experienced at getting work out-the-door and into production.  I've served as a UX Lead for multiple agile teams and adjusted my deliverables and style to meet the needs and roadmaps of my IT and Product partners.</p>
           
          </li>
          <li className="projectSeparator"></li>
          <li className="projectTitle">Marriott Aries Platform</li>
         
          <li className="projectDescription">
            <p>In 2016 Marriott released its new microservice architecture.  It was a fundamental shift not only to the technologies, but a revolutionary change in how we designed as well.  I quickly became the UX Subject Matter Expert on the platform. I established new best practices and trained colleagues in multiple disciplines on the impacts and opportunites the new architecture would have on their product / page / process.</p>
          </li>
          <li className="projectImage"><img src={SL1} alt="Cover page to a POV document focused on establishing best practices to design for Marriott’s Architecture."/></li>
          <li className="projectDescription">
            <p>Over time I've been able to transition from a philosophical and academic teacher of concepts to an implementer of strategy at a large scale.   </p>
            <ul>
              <li>Lead the creation of the platform's first pattern library</li>
              <li>Lead and champion the need for an inventory tool for all components as a means to gain efficiency and predictability</li>
              <li>Execute performance audits to benchmark successes and identify opportunities for improvement</li>
              <li>Establish standards of quality in display and behavior</li>
            </ul>
          </li>
        
          <li className="projectImage"><img src={SL3} alt="Content page from strategy guide (obfuscated)"/></li>

          <li className="projectSeparator"></li>
          <li className="projectTitle">Marriott Digital Personalization</li>
          <li className="projectDescription">
            <p>As the first UX Design Lead for Personalization I helped to craft the messages, evaluate placements, and establish best practices for marketing partners to get relevent and valuable content in-front of users with a high likelyhood of engaging. </p>
            <p>By establishing a relatable POV and codifying meaningful language I was able to bring much of the first personalized content to Marriott's digital guests.   </p>
          </li>
          <li className="projectImage"><img src={SL4} alt="Mission Statement for Marriott’s Digital Personalization and Loyalty team.  It compares personalization to a sommelier."/></li>
          <li className="projectImage"><img src={SL5} alt="Illustration of static content being delivered to all audiences."/></li>
          <li className="projectImage"><img src={SL6} alt="Depiction of Omni Channel Messaging with one message and many touchpoints."/></li>
          <li className="projectImage"><img src={SL7} alt="Illustration of targeted content being delivered to specific audiences regardless of touchpoint."/></li>
          <li className="projectImage"><img src={SL8} alt="Depiction of Targeted Omni Channel messaging where contextual content is delivered through the right touchpoint at the right time with the right content."/></li>
          
     


          <li className="projectSeparator"></li>
          <li className="projectTitle">Performance Audit &amp; Optimization</li>
          <li className="projectDescription">
            <p>While strategy is often born from lower fidelity conceptual sketches and shared understanding, it is fine tuned and turned into quantifiable business cases through competitive analysis and consistent benchmark audits.  </p>
          </li>
          <li className="projectImage"><img src={SL10} alt="Cover page for an enterprise CSS evaluation.  Using the analogy of Christmas lights to illustrate the interconnectedness and depth of problem."/></li>
          <li className="projectDescription">
            <p>I have provided stategic tactics for decreasing page load times, increasing engagement, and scaling maintainably.</p>
          </li>
          
          
         
          <li className="projectImage"><img src={SL13} alt="Executive Summary slide from CSS evaluation showing a tangled mess of Christmas lights, and breaking down what lead to the CSS problems Marriott was facing."/></li>
          <li className="projectDescription">
            <p>I've authored and delivered executive top lines of impact and LOE to address messy and mounting technical debt.</p>
          </li>
          
          <li className="projectImage"><img src={SL14} alt="Executive Summary page two.  Showing Chevy Chase’s character from the National Lampoons movies trying to get his lights to illuminate."/></li>
          <li className="projectDescription">
            <p>Illustrating complexities with simple analogies and statements of opportunity over scare tactics and boogeymen.</p>
          </li>
          <li className="projectSeparator"></li>
          <li className="projectTitle">Benchmarking and Analysis</li>
          
          <li className="projectDescription">
            <p>By presenting empirical data through competitive and intra-product landscapes I've been able to avoid the pitfalls of subjective and taste driven decision making.</p>
          </li>
          <li className="projectImage"><img src={SL15} alt="Logos of all the competitors used to benchmark Marriott’s CSS.  Comparables include  Starwood Hotels, Hilton, booking.com, Expedia, Amazon, Air BnB, Starbucks, and more."/></li>
          <li className="projectImage"><img src={IA8} alt="Selected Chart from CSS Evaluation exposing that Marriott’s CSS defines 71 background colors, AirBnB has 70, Expedia with 16, and Apple with 29 unique background colors."/></li>
          
          <li className="projectImage"><img src={IA9} alt="Selected chart from CSS Evaluation showing comparison of style weight.   Marriott’s CSS was 571KB, AirBnB 331KB, Expedia 81KB, and Apple at 1.09MB."/></li>
          <li className="projectImage"><img src={IA20} alt="Sample chart from CSS Evaluation comparing font families used on the site.  Marriott with 92, AirBnB 51, Expedia 16, and Apple 47."/></li>
          <li className="projectSeparator"></li>
          <li className="projectTitle">Emerging Channels and Markets</li>
          <li className="projectDescription">
            <p>I have held a lead role in the strategic future visioning of Marriott's digital products as well as proofs of concepts to test viability of new products, features, and customer touchpoints.  I've designed and developed "skunkworks" products that have been presented and influenced the current and future of Marriott Digital.</p>
          </li>
          <li className="projectImage"><img src={CT3} alt="Cover page for a product pitch deck based on the amazon bookmarklet shopping assistant."/></li>
          <li className="projectImage"><img src={CT4} alt="Page from pitch deck aligning product goals against Marriott competitors."/></li>
          <li className="projectImage"><img src={CT5} alt="Burning platform slide that asks how can we change the perception that lowest prices for a Marriott hotel room is found on a site other than Marriott.com."/></li>
          <li className="projectImage"><img src={CT6} alt="Slide stating that the prototype for the Roomerang product would be targeting booking.com in an effort to change perceptions of where to find the best rate on Marriott hotels."/></li>
          <li className="projectImage"><img src={CT7} alt="Slide showing how the prototype product could lead to direct bookings by identifying user signals of travel intent on other channels and platforms."/></li>
          
        </ul>



      </div>
  )
}
//----------------------------------------
function CT(){
  return(
    <div className="content">
        <h1>Code &amp; Technology</h1>
        <ul className="projectList">
        <li className="projectSeparator"></li>
        <li className="projectTitle">Designing with Code</li>
        <li className="projectImage"><img src={CT1} alt="Logos of my favorite frameworks, libraries, and languages.  HTML5, CSS3, JQuery, and React."/></li>
          <li className="projectDescription">
            <p>I've always felt like designing in the medium the product is going to be experienced is a key to achieving technically feasible and delightful features. </p>
            <p>Throughout my career I've utilized a lot of different programming languages, frameworks, content management systems, and workflow environments to deliver interactive products</p>
            <p>Currently, I'm gaining proficiency in ReactJS, but have experience in the following:</p>
            
        </li>
        
          <li className="projectDescription">
          
            
            <ul>
              <li>HTML5</li>
              <li>ReactJS / JQuery / Vanilla JS</li>
              <li>CSS / SCSS (Sass &amp; Less)</li> 
              <li>PHP</li> 
              <li>WordPress</li>
              <li>Vignette / WEM / Open Text CMS</li>
              <li>WebPack</li>
              <li>Git</li>
              <li>JSON / XML</li>
              <li>ActionScript(1,2,3)</li>
              <li>Lingo</li>

            </ul>
          </li>
          
          <li className="projectSeparator"></li>
          <li className="projectTitle">Delivering Code as Design Artifacts</li>
         
          <li className="projectDescription">
            <p>Since 2012 when I developed the ProtoSwype framework I have delivered highly functioning prototypes to serve as the source of truth for qualitative and quantitative testing for upcoming products and features and visioning of distant future features. </p>
          </li>
          <li className="projectImage"><img src={CT2} alt="Side by side images showing the functionality of my prototyping framework called ProtoSwype."/></li>
          <li className="projectSeparator"></li>
          <li className="projectDescription">
          <li className="projectTitle">ProtoSwype Framework</li>
          <p>The prototyswype framework functioned a lot like a trip to the eye doctor.  With a series of which is better options.  </p>
          <p>Each protoswype page is comprised of interface atoms that can be swiped and customized to the pattern most desirable to the user.  </p>
            
          <p>A user can delete and re-order sections of the page based on their value.  </p>
          <p>Finally, a moderator can save the output of the session with a unique snapshot of qualitative selections.</p>
            <ul>
              <li>Tinder style swipable pattern carousel that presented different in-context designs of a feature (for example 20 different global headers)</li>
              <li>Data recording of user pattern selections</li>
              <li>Generation of user assembled combination of patterns and content blocks</li> 
              <li>Rapid iteration (often in-session updates)</li>
            </ul>
          </li>
          <li className="projectSeparator"></li>
          <li className="projectTitle">Browser Plug-Ins and JavaScript Bookmarklets</li>
          <li className="projectDescription">
            <p>In addition to prototypes I designed and developed a browser plug-in inspired by amazon's shopping assistant bookmarklet. </p>
          </li>
          
          <li className="projectImage"><img src={CT8} alt="Screen shot of Roomerang messaging a user on the booking.com site that Marriott has a lower price than the one they’re currently seeing."/></li>
          <li className="projectSeparator"></li>
          <li className="projectTitle">Project Roomerang</li>
            <li className="projectDescription">
            <p> The bookmarklet scraped competitor websites for Marriott Properties and provided a real-time price difference of booking directly on marriott.com if the price was lower. When a lower price was available it would interrupt the user and message them with a unique selling proposition of booking direct.</p>
          </li>
          
       
        </ul>



      </div>
  )
}
//----------------------------------------
class RE extends React.Component {
  state = {
    numPages: null,
  }

  onDocumentLoadSuccess = (document) => {
    const { numPages } = document;
    this.setState({
      numPages,
    });
  };

  render() {
    const { numPages } = this.state;
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    return (
      <div className='content'>
      <Document
        file={resumePDF}
        onLoadSuccess={this.onDocumentLoadSuccess}
      >
        {Array.from(
          new Array(numPages),
          (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
            />
          ),
        )}
      </Document>
      </div>
    );
  }
}
//-----------------------------------------
class Burger extends React.Component {

  render(){
    return(
      this.props.doShow ?
      <div className="burger"><img src={burgerBtn}/></div> 
      : ""
    
    )
  }

}
class App extends React.Component {

  constructor(props){
    super(props)
   
    this.state = {activePage:"HO",height: window.innerHeight, 
    width: window.innerWidth, scrollStatus: ''}
    this.highestNumber = 0;
    
    this.updateDimensions = this.updateDimensions.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
   
  }
  
  handleScroll = e => {
    //let scrollTop= window.pageYOffset;
    this.scrollTop =  Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop)
    this.setState({scrollStatus: "scrolling"})
    if(this.highestNumber < this.scrollTop)
    {
      this.highestNumber = this.scrollTop;
      this.setState({scrollStatus: "up"})
    }else{
      this.setState({scrollStatus: "down"})
      this.highestNumber = this.scrollTop;
    }
     console.log(this.state.scrollStatus)
  }

  componentDidMount() {
    console.log(this.state.height);
    // Additionally I could have just used an arrow function for the binding `this` to the component...
    window.addEventListener("resize", this.updateDimensions);
    window.addEventListener("scroll", this.handleScroll);

    let loadURL = window.location.href
    let path = loadURL.substr(loadURL.indexOf("#"),loadURL.length)
    console.log(path)
    this.RouteLink(path)
    
  }

  RouteLink (p){

    
    switch(p)
    {
      case "#About":
        this.setState({activePage : 'HO'})
        break;
      case "#UXDesign":
        this.setState({activePage : 'UX'})
        break;
      case "#DesignSystems":
        this.setState({activePage : 'DS'})
        break;
      case "#IAResearch":
        this.setState({activePage : 'IA'})
        break;
      case "#Strategy":
        this.setState({activePage : 'SL'})
        break;
      case "#CodeTech":
        this.setState({activePage : 'CT'})
        break;
      case "#Resume":
        this.setState({activePage : 'RE'})
        break;
      default :
          this.setState({activePage : 'HO'})
          break;
      
    }

  }
  updateDimensions() {
    this.setState({
      height: window.innerHeight, 
      width: window.innerWidth
    });
    
    if(this.state.width<=1024){
      this.setState ({doShow:true});
    }else{
      this.setState ({doShow:false});
    }
  }
  
  ChangePage=(t,e)=>{
    let PageTo=t;
    this.setState({activePage: PageTo})
    window.scrollTo(0, 0);
    ReactGA.initialize('UA-143898102-1');
    ReactGA.pageview(t);

    setTimeout(function() { 
      if(this.state.width <=830 ){ this.ToggleView(e)}
  }.bind(this), 600)
  }
  ToggleView = (e) =>{
    
    if(this.state.hideMenu === true)
    {
      this.setState({hideMenu:false})
    }else{
      this.setState({hideMenu:true})
    }
  }

  render(){
  return (
    <div className="App">
      <div id ="mainNav" className={ this.state.hideMenu ? "sideBar off" : "sideBar"}> 
      <p className="identity">
        Tony Sciantarelli <br/><span style={{color : '#707070', fontSize: '1.25rem', marginTop:'1rem'}}>Product. UX. Strategy.</span>
      </p>
      <ul className="siteMenu">
        <li id={this.state.activePage ==='HO' ? "selected" :""} onClick={(e)=>{this.ChangePage('HO',e)}}><a href="#About">About</a></li>
        <li id={this.state.activePage ==='UX' ? "selected" :""} onClick={(e)=>{this.ChangePage('UX',e)}}><a href="#UXDesign">UX Design</a></li>
        <li id={this.state.activePage ==='DS' ? "selected" :""} onClick={(e)=>{this.ChangePage('DS',e)}}><a href="#DesignSystems">Design Systems</a></li>
        <li id={this.state.activePage ==='SL' ? "selected" :""} onClick={(e)=>{this.ChangePage('SL',e)}}><a href="#Strategy">Strategy&nbsp;&amp;&nbsp;Leadership</a></li>
        <li id={this.state.activePage ==='CT' ? "selected" :""} onClick={(e)=>{this.ChangePage('CT',e)}}><a href="#CodeTech">Code&nbsp;&amp;&nbsp;Technology</a></li>
        <li><a href={resumePDF} target="blank">Résumé </a></li>

      </ul>
      <div id='social'>
        <div className='socialLinks'>
          <a href="https://twitter.com/tsciant" target="_blank" rel="noopener">
            <img src={twitter} alt="Tony on Twitter" />
          </a>
          <a href="https://github.com/tsciant" target="_blank" rel="noopener">
            <img src={github} alt="Tony on Github" />
          </a>
          <a href="https://linkedIn.com/in/tsciant" target="_blank" rel="noopener">
            <img src={linkedIn} alt="Tony on LinkedIn" />
          </a>
        </div>
        
      </div>
        <div id='burgerContainer' className ={this.state.scrollStatus === "up" ? "hidden" : "shown"} onClick ={(e)=>{this.ToggleView(e)}}>
          <Burger doShow={ this.state.width <=1024 ? true : false }  />
        </div>
      </div>
      
      <div >
         <PageController curPage={this.state.activePage}  />
      </div>
    </div>
  );
}}

export default App;
